import React from 'react'
import Layout from '@components/Layout'
import PageTitle from '@components/PageTitle'
import { TitleParagraph } from '@global'

const NotFoundPage = () => (
  <Layout>
    <PageTitle title="Page Not Found" />
    <TitleParagraph>You requested a page that does not exist</TitleParagraph>
  </Layout>
)

export default NotFoundPage
